module.exports = {
  sassVariables: {
    primary: '#1874cd',
    secondary: '#272727',
    'navbar-back-rotation': '230deg',
    'navbar-link-rotation': '-100deg',
    'login-background': "url('/images/obey-background.jpg')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/logo_light.png',
    fullLogo: '/images/logo_dark.png',
    faviconLogo: '/images/logo_dark.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
